const Arrow = ({ width, height }) => (
  <svg
    width={width || 13}
    height={height || 13}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0015 3.414L2.39447 12.021L0.980469 10.607L9.58647 2H2.00147V0H13.0015V11H11.0015V3.414V3.414Z"
      fill="#737373"
    />
  </svg>
);

export default Arrow;
