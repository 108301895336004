import Section from '@components/ui/Section';
import Link from 'next/link';
import styled, { keyframes } from 'styled-components';

import data from './data';

const cvList = data.map((el) => {
  if (el.cv[el.cv.length - 1].stack !== '') {
    el.cv.push({
      id: el.cv[el.cv.length - 1].id + 1,
      stack: '',
    });
  }

  return {
    cv: [
      { id: 1, stack: el.cv },
      { id: 2, stack: el.cv },
    ],
  };
});

const left = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%)
  }
`;

const right = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(100%)
  }
`;

const StyledContainer = styled(Section)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 750px;
  gap: 200px;
  padding: 128px 0 136px;
  background: ${({ theme }) => theme.colors.primary.dark};
`;

const StyledList = styled.div`
  width: 100vw;
  position: relative;

  :hover div {
    animation-play-state: paused;
  }
  @media screen and (max-width: 1250px) {
    width: 130vw;
  }

  @media screen and (max-width: 1024px) {
    width: 175vw;
  }

  @media screen and (max-width: 519px) {
    width: 240vw;
  }

  @media screen and (max-width: 450px) {
    width: 360vw;
  }

  @media screen and (max-width: 320px) {
    width: 440vw;
  }
`;

const LeftList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 74px;
  animation: ${left} 12s infinite linear;
  position: absolute;
  top: 0;
  left: 0;

  :nth-child(2) {
    left: 100%;
  }
`;

const RightList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 74px;
  animation: ${right} 12s infinite linear;
  position: absolute;
  top: 0;
  right: 0;

  :nth-child(2) {
    right: 100%;
  }
`;

const Text = styled.a`
  font-weight: 500;
  font-size: 60px;
  line-height: 74px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.secondary.silver_1000};
  transition: color 0.25s;
  white-space: nowrap;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primary.orange};
  }
`;

const CvSection = () => (
  <StyledContainer className="blackSection">
    <StyledList>
      {cvList[0].cv.map((cv) => (
        <LeftList key={cv.id}>
          {cv.stack.map((el) => (
            <Link key={el.id} href="/outstaff" passHref>
              <Text>{el.stack}</Text>
            </Link>
          ))}
        </LeftList>
      ))}
    </StyledList>
    <StyledList>
      {cvList[1].cv.map((cv) => (
        <RightList key={cv.id}>
          {cv.stack.map((el) => (
            <Link key={el.id} href="/outstaff" passHref>
              <Text>{el.stack}</Text>
            </Link>
          ))}
        </RightList>
      ))}
    </StyledList>
    <StyledList>
      {cvList[2].cv.map((cv) => (
        <LeftList key={cv.id}>
          {cv.stack.map((el) => (
            <Link key={el.id} href="/outstaff" passHref>
              <Text>{el.stack}</Text>
            </Link>
          ))}
        </LeftList>
      ))}
    </StyledList>
  </StyledContainer>
);

export default CvSection;
