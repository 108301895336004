import AdobeIllustrator from './AdobeIllustrator.svg';
import AdobePhotoshop from './AdobePhotoshop.svg';
import AfterEffects from './AfterEffects.svg';
import Angular from './Angular.svg';
import Appium from './Appium.svg';
import Browser from './Browser.svg';
import ChartJS from './ChartJS.svg';
import Django from './Django.svg';
import ES from './ES.svg';
import Experiecedesign from './Experiecedesign.svg';
import Figma from './Figma.svg';
import Flutter from './Flutter.svg';
import GraphQL from './GraphQL.svg';
import Ionic from './Ionic.svg';
import JavaScript from './JavaScript.svg';
import Jira from './Jira.svg';
import Jmeter from './Jmeter.svg';
import Kotlin from './Kotlin.svg';
import MongoDB from './MongoDB.svg';
import MUI from './MUI.svg';
import MySQL from './MySQL.svg';
import NestJS from './NestJS.svg';
import NextJS from './NextJS.svg';
import Nodejs from './Nodejs.svg';
import PostgreSQL from './PostgreSQL.svg';
import Postman from './Postman.svg';
import Python from './Python.svg';
import React from './React.svg';
import ReactNative from './ReactNative.svg';
import Redux from './Redux.svg';
import Sass from './Sass.svg';
import Selenium from './Selenium.svg';
import Sketch from './Sketch.svg';
import Storybook from './Storybook.svg';
import Tailwind from './Tailwind.svg';
import Testrail from './Testrail.svg';
import TypeScript from './TypeScript.svg';
import Vue from './Vue.svg';
import Xcode from './Xcode.svg';

export default {
  AdobeIllustrator,
  AdobePhotoshop,
  AfterEffects,
  Angular,
  Appium,
  Browser,
  Django,
  ES,
  Experiecedesign,
  Figma,
  Flutter,
  Ionic,
  Jira,
  Jmeter,
  Kotlin,
  MongoDB,
  MySQL,
  NestJS,
  Nodejs,
  PostgreSQL,
  Postman,
  Python,
  React,
  ReactNative,
  Selenium,
  Sketch,
  Testrail,
  TypeScript,
  Vue,
  Xcode,
  Sass,
  ChartJS,
  GraphQL,
  MUI,
  Tailwind,
  Storybook,
  Redux,
  JavaScript,
  NextJS,
};
