const casesData = [
  {
    id: 'blockLeft',
    block: [
      {
        id: 1,
        img: '/images/projects/picture1.png',
        title: 'card_title_1',
        period: 'card_duration_1',
        description: 'card_description_1',
        height: '573px',
        width: '537px',
        info: [
          {
            title: 'card_black_title',
            text: ['card_black_description_1'],
          },
          {
            title: 'task_title',
            text: ['task_description_1'],
          },
        ],
        stack: ['React', 'Redux', 'Next', 'MySQL', 'Typescript', 'Python'],
        case: 9,
      },
      {
        id: 2,
        img: '/images/projects/picture3.png',
        title: 'card_title_3',
        period: 'card_duration_3',
        description: 'card_description_2',
        height: '742px',
        width: '537px',
        info: [
          {
            title: 'card_black_title',
            text: ['card_black_description_2'],
          },
          {
            title: 'task_title',
            text: ['task_description_2'],
          },
        ],
        stack: ['Sass', 'ChartJS', 'GraphQL', 'React'],
        case: 5,
      },
    ],
  },
  {
    id: 'blockRight',
    block: [
      {
        id: 3,
        description: 'card_description_5',
      },
      {
        id: 4,
        img: '/images/projects/picture2.png',
        title: 'card_title_2',
        period: 'card_duration_2',
        description: 'card_description_3',
        width: '567px',
        height: '526px',
        info: [
          {
            title: 'card_black_title',
            text: ['card_black_description_3'],
          },
          {
            title: 'task_title',
            text: ['task_description_3'],
          },
        ],
        stack: ['AWS', 'ActiveAdmin', 'PostgreSQL', 'React', 'Redux'],
        case: 4,
      },
      {
        id: 5,
        img: '/images/projects/picture4.png',
        title: 'card_title_2',
        period: 'card_duration_4',
        description: 'card_description_4',
        width: '567px',
        height: '526px',
        info: [
          {
            title: 'card_black_title',
            text: ['card_black_description_4'],
          },
          {
            title: 'task_title',
            text: ['task_description_4'],
          },
        ],
        stack: ['AWS', 'Figma', 'Storybook', 'Typescript', 'MUI', 'DynamoDB'],
        case: 6,
      },
    ],
  },
];

export default casesData;
