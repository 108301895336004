import AnimatedContainer from '@components/ui/AnimatedContainer';
import ArrowLink from '@components/ui/ArrowLink';
import Container from '@components/ui/Container';
import MobileInfoContent from '@components/ui/MobileInfoContent';
import Section from '@components/ui/Section';
import SectionTitle from '@components/ui/SectionTitle';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import styled from 'styled-components';

import casesData from './data/casesData';

const StyledSection = styled(Section)`
  position: relative;
  padding: 128px 0;
  background: ${({ theme }) => theme.colors.secondary.silver_1000};

  @media screen and (max-width: 1024px) {
    padding: 128px 0 80px;
  }

  @media screen and (max-width: 519px) {
    padding: 80px 0;
  }
`;

const StyledContainer = styled(Container)`
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;

const ProjectTitle = styled(SectionTitle)`
  font-weight: 500;
  font-size: 68px;
  line-height: 80px;
  letter-spacing: 0.02em;
  margin: 0 0 96px;
  color: ${({ theme }) => theme.colors.primary.light_500};
  text-transform: none;

  @media screen and (max-width: 790px) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: 320px) {
    font-size: 54px;
  }
`;

const ProjectDescription = styled.span`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.02em;
  margin: 0 0 96px;
  color: ${({ theme }) => theme.colors.primary.light_500};

  @media screen and (max-width: 790px) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: 320px) {
    font-size: 32px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 93px;
  padding-top: 96px;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    margin-bottom: 64px;
    gap: 48px;

    #blockRight div:nth-last-child(-n + 2) {
      display: none;
    }
  }
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 93px;

  @media screen and (max-width: 1024px) {
    gap: 48px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.h1`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.02em;
  margin: 32px 0 0;
  color: ${({ theme }) => theme.colors.primary.light_500};
`;

const ContentPeriod = styled.span`
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.02em;
  margin: 16px 0 4px;
  color: ${({ theme }) => theme.colors.primary.light_400};
`;

const ContentDescription = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary.light_400};

  @media screen and (max-width: 790px) {
    max-width: 480px;
  }

  @media screen and (max-width: 519px) {
    max-width: 450px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  background: ${({ img }) => `url(${img}) no-repeat`};
  background-size: cover;
  position: relative;
  cursor: pointer;

  :hover #blackContainer {
    opacity: 1;
  }

  @media screen and (max-width: 1370px) {
    height: 540px;
  }

  @media screen and (max-width: 1248px) {
    height: 568px;
  }

  @media screen and (max-width: 1065px) {
    height: 596px;
  }

  @media screen and (max-width: 1024px) {
    #blackContainer {
      opacity: 1;
    }
  }

  @media screen and (max-width: 425px) {
    height: 740px;
  }

  @media screen and (max-width: 320px) {
    height: 850px;
  }

  @media screen and (max-width: 280px) {
    height: 1054px;
  }
`;

const BlackContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  transition: opacity 0.25s linear;
  background: ${({ theme }) => theme.colors.primary.light_500};

  @media screen and (max-width: 1370px) {
    height: 540px;
  }

  @media screen and (max-width: 1248px) {
    height: 568px;
  }

  @media screen and (max-width: 1065px) {
    height: 596px;
  }

  @media screen and (max-width: 425px) {
    height: 740px;
  }

  @media screen and (max-width: 320px) {
    height: 850px;
  }

  @media screen and (max-width: 280px) {
    height: 1054px;
  }
`;

const ProjectSection = () => {
  const { t } = useTranslation('cases');

  return (
    <StyledSection id="cases">
      <StyledContainer>
        <AnimatedContainer className="value_project-title">
          <ProjectTitle>{t('title')}</ProjectTitle>
        </AnimatedContainer>
        <AnimatedContainer className="value_project-description">
          <ProjectDescription>
            <Trans i18nKey="cases:description" />
          </ProjectDescription>
        </AnimatedContainer>
        <ContentWrapper>
          {casesData.map((el) => (
            <ContentBlock id={el.id} key={el.id}>
              {el.block.map((item) => (
                <Content key={`block-${item.id}`}>
                  {item.img ? (
                    <Link href={`/cases/${item.case}`} passHref>
                      <ImageContainer
                        img={item.img}
                        width={item.width}
                        height={item.height}
                      >
                        <BlackContainer
                          width={item.width}
                          height={item.height}
                          id="blackContainer"
                        >
                          <MobileInfoContent data={item} />
                        </BlackContainer>
                      </ImageContainer>
                    </Link>
                  ) : null}
                  {item.title ? (
                    <ContentTitle>{t(item.title)}</ContentTitle>
                  ) : null}
                  {item.period ? (
                    <ContentPeriod>{t(item.period)}</ContentPeriod>
                  ) : null}
                  <ContentDescription>{t(item.description)}</ContentDescription>
                  {item.case ? (
                    <ArrowLink href={`/cases/${item.case}`}>
                      {t('cta_2')}
                    </ArrowLink>
                  ) : null}
                </Content>
              ))}
            </ContentBlock>
          ))}
        </ContentWrapper>
      </StyledContainer>
    </StyledSection>
  );
};
export default ProjectSection;
