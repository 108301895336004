import FormSection from '@components/FormSection';
import styled from 'styled-components';

import CvSection from './modules/CvSection';
import HeroSection from './modules/HeroSection';
import InfoSection from './modules/InfoSection';
import ProjectSection from './modules/ProjectSection';
import Scroll from './modules/Scroll';
import ServicesBlock from './modules/ServicesBlock';
import StackSection from './modules/StackSection';
// import VacancySection from './modules/VacancySection';

const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.silver_1000};
`;

const MainPage = () => (
  <PageWrapper id="main">
    <Scroll />
    <HeroSection />
    <InfoSection />
    <ServicesBlock />
    <StackSection />
    <CvSection />
    <ProjectSection />
    {/* <VacancySection /> */}
    <FormSection />
  </PageWrapper>
);

export default MainPage;
