const data = [
  {
    cv: [
      { id: 1, stack: 'python' },
      { id: 2, stack: 'django' },
      { id: 3, stack: 'typescript' },
      { id: 4, stack: 'flutter' },
      { id: 5, stack: 'figma' },
    ],
  },
  {
    cv: [
      { id: 1, stack: 'node.js' },
      { id: 2, stack: 'angular' },
      { id: 3, stack: 'Ionic' },
      { id: 4, stack: 'jira' },
    ],
  },
  {
    cv: [
      { id: 1, stack: 'nestjs' },
      { id: 2, stack: 'react native' },
      { id: 3, stack: 'selenium' },
      { id: 4, stack: 'mysql' },
    ],
  },
];

export default data;
