import ArrowRight from '@components/ui/ArrowRight';
import Container from '@components/ui/Container';
import ScrollValue from '@components/ui/ScrollValue';
import Section from '@components/ui/Section';
import useMediaQuery from 'hooks/useMediaQuery';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';

const values = [1, 2, 3, 4, 5];

const StyledSection = styled(Section)`
  position: relative;
  padding: 128px 0;
  background-color: ${({ theme }) => theme.colors.primary.light_500};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 590px;
  min-width: 570px;
  max-height: 82px;
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 68px;
  line-height: 120%;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.primary.white};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// styles for different component for tablet and mobile

const ValueContainerSection = styled(Section)`
  padding-top: 96px;
  padding-bottom: 96px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary.black};
`;

const ValueTitle = styled.h2`
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.primary.white};
  margin: 0;
  margin-bottom: 64px;
`;

const ValueTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 48px;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.primary.white};
  margin: 0;
`;

const IndustriesSection = () => {
  const { t } = useTranslation('industries');
  const [windowHeight, setWindowHeight] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowHeight(window.innerHeight);
    }
  }, []);

  const isMobileVersion = useMediaQuery(1023);

  if (isMobileVersion) {
    return (
      <ValueContainerSection className="blackSection">
        <Container>
          <ValueTitle>{t('title')}</ValueTitle>
          <ValueTextWrapper>
            {values.map((value, index) => (
              <Text key={`value-industries-${index + 1}`}>
                {t(`text_${value}`)}
              </Text>
            ))}
          </ValueTextWrapper>
        </Container>
      </ValueContainerSection>
    );
  }

  return (
    <StyledSection className="blackSection">
      <StyledContainer>
        <Controller>
          <Scene duration={windowHeight * 0.5} pin>
            <TitleWrapper id="floated-title">
              <Title>{t('title')}</Title>
              <ArrowRight />
            </TitleWrapper>
          </Scene>
          <TextWrapper>
            {values.map((value, index) => (
              <ScrollValue
                className={`value-${value}`}
                key={`value-industries-${index + 1}`}
              >
                {t(`text_${value}`)}
              </ScrollValue>
            ))}
          </TextWrapper>
        </Controller>
      </StyledContainer>
    </StyledSection>
  );
};

export default IndustriesSection;
