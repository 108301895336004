import Container from '@components/ui/Container';
import Section from '@components/ui/Section';
import AnimationTitle from '@ui/AnimationTitle';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import offset from 'utils/offset';

const StyledSection = styled(Section)`
  background: ${({ theme }) => theme.colors.secondary.silver_1000};
  padding: 208px 0 0;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;

  @media screen and (max-width: 375px) {
    padding: 130px 0 0;
  }

  @media screen and (max-width: 320px) {
    padding: 88px 0 0;
  }
`;

const StyledContainer = styled(Container)`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
`;

const HeroText = styled.h1`
  margin: 0;
  position: relative;
  color: ${({ theme }) => theme.colors.primary.light_500};
  font-size: 68px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  line-height: 114%;

  @media screen and (max-width: 790px) {
    font-size: 35px;
  }

  @media screen and (max-width: 520px) {
    font-size: 28px;
  }
`;

const OrangeText = styled.span`
  padding: 0 16px;
  background: ${({ theme }) => theme.colors.primary.orange};
  color: ${({ theme }) => theme.colors.secondary.silver_1000};
`;

const DescriptionWrapper = styled.div`
  max-width: 644px;
  min-height: 100px;
  margin-top: 64px;

  @media screen and (max-width: 375px) {
    margin-top: 30px;
  }
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colors.primary.light_400};
  position: relative;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.02em;
  font-weight: 400;

  @media screen and (max-width: 375px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

const HeroSection = () => {
  const { t } = useTranslation('hero');
  const [isShow, setShow] = useState(true);

  const setHighlight = () => {
    if (typeof document !== 'undefined') {
      const teamSections = document.querySelector('#info');

      if (teamSections) {
        const elStart = offset(teamSections);
        const elEnd = elStart + teamSections.getBoundingClientRect().height;

        setShow(window.pageYOffset < elEnd);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', setHighlight);
    }

    return () => window.removeEventListener('scroll', setHighlight);
  }, []);

  return (
    <StyledSection>
      <StyledContainer show={isShow}>
        <AnimationTitle>
          <HeroText>
            <Trans i18nKey="hero:title" components={{ c: <OrangeText /> }} />
          </HeroText>
          <DescriptionWrapper>
            <Description>{t('description')}</Description>
          </DescriptionWrapper>
        </AnimationTitle>
      </StyledContainer>
    </StyledSection>
  );
};

export default HeroSection;
