export default [
  {
    id: 0,
    title: 'Backend',
    techs: [
      'Python',
      'Django',
      'Node.js',
      'NestJS',
      'PostgreSQL',
      'MongoDB',
      'MySQL',
    ],
  },
  {
    id: 1,
    title: 'Frontend',
    techs: ['ES', 'TypeScript', 'Vue', 'Angular', 'React'],
  },
  {
    id: 2,
    title: 'Mobile',
    techs: ['React Native', 'Flutter', 'Ionic', 'Kotlin'],
  },
  {
    id: 3,
    title: 'Design',
    techs: [
      'Figma',
      'Sketch',
      'Adobe Photoshop',
      'Adobe Illustrator',
      'Experiece design',
      'After Effects',
    ],
  },
  {
    id: 4,
    title: 'QA',
    techs: [
      'Jira',
      'Browser',
      'Testrail',
      'Xcode',
      'Selenium',
      'Postman',
      'Appium',
      'Jmeter',
    ],
  },
];
