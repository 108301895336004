const ArrowRight = () => (
  <svg
    width="86"
    height="48"
    viewBox="0 0 86 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.344 22.0013L59.616 11.2733L62.444 8.44531L78 24.0013L62.444 39.5573L59.616 36.7293L70.344 26.0013H5.5V22.0013H70.344Z"
      fill="#FF6B00"
    />
  </svg>
);

export default ArrowRight;
