import lineBg from '@public/images/line.png';
import orangeLineBg from '@public/images/orangeLine.png';
import Container from '@ui/Container';
import Form from '@ui/Form';
import Section from '@ui/Section';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

const FormSectionWrapper = styled(Section)`
  background: ${({ page }) =>
    `url(${
      page === 'about-us' ? orangeLineBg.src : lineBg.src
    }) no-repeat top left`};
  background-size: cover;
  padding: 140px 0px 83px;
  background-color: ${({ theme }) => theme.colors.primary.black};
  border-bottom: ${({ theme }) =>
    `2px solid ${theme.colors.secondary.silver_50}`};
  position: relative;

  @media screen and (max-width: 519px) {
    padding: 140px 10px 83px;
  }
`;

const FormTitle = styled.h1`
  max-width: 856px;
  font-weight: 500;
  font-size: 68px;
  line-height: 80px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.primary.white};
  margin: 0 0 98px;

  @media screen and (max-width: 790px) {
    font-size: 48px;
    line-height: 60px;
    margin: 0 0 146px;
  }

  @media screen and (max-width: 520px) {
    margin: 0 0 98px;
  }
`;

const FormSubtitle = styled.span`
  background: ${({ theme }) => theme.colors.primary.orange};
`;

const FormWrapper = styled.div`
  display: flex;
  min-height: 236px;
`;

const FormSection = ({ page }) => {
  const { t } = useTranslation('form');

  return (
    <FormSectionWrapper page={page} id="form">
      <Container>
        <FormTitle>
          {t('form_title')}
          <FormSubtitle>{t('form_title_2')}</FormSubtitle>
        </FormTitle>
        <FormWrapper>
          <Form />
        </FormWrapper>
      </Container>
    </FormSectionWrapper>
  );
};

export default FormSection;
