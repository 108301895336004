import IndustriesSection from './sections/IndustriesSection';
import ServicesSection from './sections/ServicesSection';
import WorkFormatSection from './sections/WorkFormatSection';

const ServicesBlock = () => (
  <>
    <ServicesSection />
    <IndustriesSection />
    <WorkFormatSection />
  </>
);

export default ServicesBlock;
