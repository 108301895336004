import styled from 'styled-components';

const SectionTitle = styled.h2`
  font-size: 46px;
  font-family: 'Pragmatica';
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.06em;
  margin: 0;
  margin-bottom: 60px;

  @media screen and (max-width: 790px) {
    font-size: 35px;
  }
`;

export default SectionTitle;
