import styled, { keyframes } from 'styled-components';

const fadein = keyframes`
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const StyledContainer = styled.div`
  transform: translateX(-60px);
  opacity: 0;
  animation: ${fadein} 1.5s 0.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
`;

const AnimationTitle = ({ id, children }) => (
  <StyledContainer key={id}>{children}</StyledContainer>
);

export default AnimationTitle;
