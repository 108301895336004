import { useEffect, useState } from 'react';
import { Scene } from 'react-scrollmagic';
import styled from 'styled-components';

const Text = styled.h4`
  opacity: ${({ progress }) => progress};
  transform: ${({ progress, textHeight }) =>
    `translate3d(0px, ${textHeight - textHeight * progress}px, 0px)`};
  margin: 20px 0;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: ${({ theme, active }) =>
    active ? theme.colors.primary.white : theme.colors.secondary.silver_40};
  transition: all 0.15s linear;
`;

const ScrollValue = ({ className, ...props }) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState(null);

  const setHighlight = () => {
    if (typeof document !== 'undefined') {
      const currentValue = document.querySelector(`.${className}`);
      const floatedTitle = document.querySelector('#floated-title');

      const textPosition = currentValue?.getBoundingClientRect().top;
      const textHeight = currentValue?.getBoundingClientRect().height;
      setHeight(textHeight);

      const titlePosition = floatedTitle?.getBoundingClientRect().top;
      const titleHeight = floatedTitle?.getBoundingClientRect().height;

      const textEndPosition = textPosition - titleHeight / 2;

      const isStart =
        titlePosition - titleHeight / 2 === textPosition ||
        textPosition < titlePosition + titleHeight / 2;
      const isEnd = titlePosition - textHeight > textEndPosition;

      if (isStart && !isEnd) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', setHighlight);
    }

    return () => window.removeEventListener('scroll', setHighlight);
  }, []);

  return (
    <Scene triggerHook={0.8} reverse>
      {(progress) => (
        <Text
          active={active}
          className={className}
          progress={progress}
          textHeight={height}
        >
          {props.children}
        </Text>
      )}
    </Scene>
  );
};

export default ScrollValue;
