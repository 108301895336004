import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  justify-content: ${({ position }) =>
    position === 'right' ? 'flex-end' : 'flex-start'};
  flex-wrap: wrap;
  column-gap: ${({ columnGap }) => (columnGap ? `${columnGap}px` : '24px')};
  row-gap: 23px;

  span {
    display: block;
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '24px')};
    font-weight: 400;
    padding: 10px;
    border: ${({ color, theme, borderSize }) => `${borderSize}px solid
        ${
          color === 'dark'
            ? theme.colors.primary.white
            : theme.colors.primary.light_300
        }`};
    color: ${({ color, theme }) => {
      if (color === 'dark') {
        return theme.colors.primary.white;
      }

      if (color === 'gray') {
        return theme.colors.primary.light_400;
      }

      return theme.colors.primary.light_500;
    }};
  }
`;

const StackList = ({
  data,
  position,
  color,
  borderSize,
  columnGap,
  fontSize,
  width,
}) => {
  const { t } = useTranslation('outstaff');

  return (
    <StyledContainer
      position={position}
      color={color}
      borderSize={borderSize}
      columnGap={columnGap}
      fontSize={fontSize}
      width={width}
    >
      {data.map((item) => (
        <span key={item}>{t(item)}</span>
      ))}
    </StyledContainer>
  );
};

export default StackList;
