import AnimatedContainer from '@components/ui/AnimatedContainer';
import Container from '@components/ui/Container';
import Section from '@components/ui/Section';
import SectionTitle from '@components/ui/SectionTitle';
import ArrowRight from '@ui/ArrowRight';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

import {
  ServicesDescription,
  StyledDescription,
  StyledText,
  StyledTitle,
} from '../StyledComponents';

const StyledSection = styled(Section)`
  background: ${({ theme }) => theme.colors.secondary.silver_1000};
  position: relative;
  padding: 128px 0 0;
`;

const Title = styled(SectionTitle)`
  font-weight: 500;
  font-size: 68px;
  letter-spacing: 0.02em;
  line-height: 80px;
  text-transform: none;
  margin: 0 0 48px;
  color: ${({ theme }) => theme.colors.primary.light_500};

  @media screen and (max-width: 270px) {
    font-size: 56px;
  }
`;

const StyledLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 38px;
  text-transform: none;
  padding-top: 29px;
  margin: 0;
  color: ${({ theme, color }) =>
    color === 'white'
      ? theme.colors.primary.white
      : theme.colors.primary.light_500};
  column-gap: 33px;
  max-width: 510px;
  cursor: pointer;

  svg path {
    fill: ${({ theme, color }) =>
      color === 'white'
        ? theme.colors.primary.white
        : theme.colors.primary.orange};
  }

  :hover {
    color: ${({ theme, color }) =>
      color === 'white'
        ? theme.colors.secondary.silver_800
        : theme.colors.secondary.silver_70};

    svg path {
      fill: ${({ theme, color }) =>
        color === 'white'
          ? theme.colors.secondary.silver_800
          : theme.colors.primary.orange_700};
    }
  }

  @media screen and (max-width: 1024px) {
    column-gap: 13px;
  }

  @media screen and (max-width: 470px) {
    max-width: 300px;
    font-size: 24px;
  }

  @media screen and (max-width: 320px) {
    font-size: 24px;
  }
`;

const WorkFormatSection = () => {
  const { t } = useTranslation('workFormat');

  const values = [
    {
      class: 1,
      title: t('subtitle_1'),
      component: (
        <>
          <StyledText>{t('text_1')}</StyledText>
          <Link href="/contacts" passHref>
            <StyledLink>
              {t('cta_1')}
              <ArrowRight />
            </StyledLink>
          </Link>
        </>
      ),
    },
    {
      class: 2,
      title: t('subtitle_2'),
      component: (
        <>
          <StyledText>{t('text_2')}</StyledText>
          <Link href="/contacts" passHref>
            <StyledLink>
              {t('cta_2')}
              <ArrowRight />
            </StyledLink>
          </Link>
        </>
      ),
    },
    {
      class: 3,
      title: t('subtitle_4'),
      component: (
        <>
          <StyledText>{t('text_3')}</StyledText>
          <Link href="/outstaff" passHref>
            <StyledLink>
              {t('cta_3')}
              <ArrowRight />
            </StyledLink>
          </Link>
        </>
      ),
    },
  ];

  return (
    <StyledSection>
      <Container>
        <AnimatedContainer className="value_format-title">
          <Title>{t('title')}</Title>
        </AnimatedContainer>
        {values.map((value, index) => (
          <AnimatedContainer
            className={`value-format-${value.class}`}
            key={`value-format-${index + 1}`}
          >
            <ServicesDescription>
              <StyledTitle>{value.title}</StyledTitle>
              <StyledDescription>{value.component}</StyledDescription>
            </ServicesDescription>
          </AnimatedContainer>
        ))}
      </Container>
    </StyledSection>
  );
};

export default WorkFormatSection;
