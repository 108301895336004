import Footer from '@components/Footer';
import Header from '@components/Header';
import MainPage from '@components/Main';
import Head from 'next/head';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const isProd = process.env.NODE_ENV === 'production';

export const getServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      'cookieConsent',
      'hero',
      'cases',
      'info',
      'form',
      'services',
      'industries',
      'workFormat',
      'contacts',
      'benefits',
      'routes',
      'policy',
      'common',
      'vacancy',
    ])),
  },
});

export default function Home() {
  const { t } = useTranslation('common');

  return (
    <>
      <Head>
        <title>{t('head_title')}</title>
        <meta name="description" content={t('head_description')} />
        <meta name="keywords" content={t('head_keywords')} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="alternate" href="https://fojin.tech/" hrefLang="x-default" />
        <link rel="alternate" href="https://fojin.tech/" hrefLang="ru" />
      </Head>
      {isProd && (
        <>
          <Script
            strategy="beforeInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=G-7S3X30V44B"
          />
          <Script
            id="gtag-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'G-7S3X30V44B');
          `,
            }}
          />
          <Script
            id="yandex-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        
          ym(88895329, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
          });
         `,
            }}
          />
          <noscript>
            <div>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="https://mc.yandex.ru/watch/88895329"
                style={{ position: 'absolute', left: '-9999px' }}
                alt=""
              />
            </div>
          </noscript>
        </>
      )}
      <>
        <Header />
        <MainPage />
        <Footer />
      </>
    </>
  );
}
