import Arrow from '@ui/Arrow';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import offset from 'utils/offset';

const StyledContainer = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  right: 0px;
  bottom: -5px;
  z-index: 5;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const StyledScroll = styled.div.attrs((props) => ({
  style: {
    transform: `rotate(${props.scroll / 5}deg)`,
  },
}))`
  transition: transform 0.1s linear;
`;

const StyledArrow = styled.div`
  position: absolute;
  left: 94px;
  top: 96px;

  svg path {
    fill: ${({ theme, hover, isBlack }) => {
      if (hover || isBlack) {
        return theme.colors.secondary.silver_1000;
      }

      return theme.colors.primary.light_500;
    }};
  }
`;

const TextButton = styled.text`
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.02em;
  fill: ${({ theme, hover, isBlack }) => {
    if (hover || isBlack) {
      return theme.colors.secondary.silver_1000;
    }

    return theme.colors.primary.light_500;
  }};
`;

const Circle = styled.div`
  position: absolute;
  left: 18px;
  top: 20px;
  background-color: ${({ theme }) => theme.colors.primary.orange};
  width: 165px;
  height: 165px;
  border-radius: 50%;
  transform: ${({ hover }) => `scale(${hover ? '1, 1' : '0, 0'})`};
  transition: transform 0.35s ease-in;
`;

const Scroll = () => {
  const { t } = useTranslation('hero');
  const [scroll, setScroll] = useState(0);
  const [isHover, setHover] = useState(false);
  const [isBlackSection, setBlackSection] = useState(false);
  const [isShow, setShow] = useState(true);

  const setHighlight = () => {
    setScroll(window.pageYOffset);

    if (typeof document !== 'undefined') {
      const blackSections = document.querySelectorAll('.blackSection');
      const scrollButton = document.querySelector('#scroll');

      const scrollButtonPosition = offset(scrollButton);

      const arrowCenterPosition =
        // eslint-disable-next-line no-unsafe-optional-chaining
        scrollButtonPosition + scrollButton?.getBoundingClientRect().height / 2;

      let currentSection;

      blackSections.forEach((section) => {
        const elStart = offset(section);
        const elEnd = elStart + section.getBoundingClientRect().height;
        if (arrowCenterPosition >= elStart && arrowCenterPosition <= elEnd) {
          currentSection = section;
        }
      });

      if (currentSection) {
        setBlackSection(true);

        if (currentSection.id === 'info') {
          setBlackSection(true);
          const child = document.getElementById('move');
          const { transform } = child.style;
          const translate = transform.split('(')[1].split(')')[0].split(',');

          if (translate[0].slice(1, -1) >= 8) {
            setBlackSection(false);
          }
        }

        if (currentSection.id === 'outstaff') {
          setBlackSection(true);
          const child = document.getElementById('tween');
          const { transform } = child.style;

          if (transform !== 'translate(0px, 0%)') {
            setBlackSection(false);
          } else {
            setBlackSection(true);
          }
        }
      } else {
        setBlackSection(false);
      }
    }
  };

  useEffect(() => {
    let observer;

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', setHighlight);

      const formSection = document.querySelector('#form');

      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          setShow(!entry.isIntersecting);
        });
      });

      observer?.observe(formSection);
    }

    return () => {
      window.removeEventListener('scroll', setHighlight);
      observer?.disconnect();
    };
  }, []);

  return (
    <Link href="#form" passHref>
      <StyledContainer
        show={isShow}
        id="scroll"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Circle hover={isHover} />
        <StyledArrow isBlack={isBlackSection} hover={isHover}>
          <Arrow width="16" height="16" />
        </StyledArrow>
        <StyledScroll scroll={scroll}>
          <svg
            x="0px"
            y="0px"
            width="200px"
            height="200px"
            viewBox="0 0 200 200"
          >
            <defs>
              <path
                id="circlePath"
                d=" M 100, 102 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
              />
            </defs>
            <g>
              <TextButton hover={isHover} isBlack={isBlackSection}>
                <textPath startOffset="0%" xlinkHref="#circlePath">
                  {t('cta')}
                </textPath>
                <textPath startOffset="50%" xlinkHref="#circlePath">
                  {t('cta')}
                </textPath>
              </TextButton>
            </g>
          </svg>
        </StyledScroll>
      </StyledContainer>
    </Link>
  );
};

export default Scroll;
