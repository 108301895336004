import Link from 'next/link';
import styled, { css } from 'styled-components';

import Button from './Button';

const ArrowTap = css`
  width: fit-content;
  display: flex;
  color: ${({ theme, color }) =>
    color === 'black'
      ? theme.colors.primary.white
      : theme.colors.primary.light_500};
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  transition: 0.25s ease-in-out;
  gap: 33px;
  cursor: pointer;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  svg {
    flex-shrink: 0;

    path {
      stroke: none;
    }
  }

  :hover {
    opacity: 0.6;
  }

  @media (max-width: 320px) {
    gap: 10px;
  }
`;

const StyledText = styled.span`
  text-align: start;
`;

const StyledLink = styled.a`
  ${ArrowTap}
`;

const StyledButton = styled(Button)`
  ${ArrowTap}
`;

const ArrowSvg = () => (
  <svg
    width="86"
    height="48"
    viewBox="0 0 86 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.344 22.0013L59.616 11.2733L62.444 8.44531L78 24.0013L62.444 39.5573L59.616 36.7293L70.344 26.0013H5.5V22.0013H70.344Z"
      fill="#FF6B00"
    />
  </svg>
);

const ArrowLink = ({ children, href, onClick, color }) =>
  href ? (
    <Link href={href} passHref>
      <StyledLink color={color}>
        {children}
        <ArrowSvg />
      </StyledLink>
    </Link>
  ) : (
    <StyledButton onClick={onClick}>
      <StyledText>{children}</StyledText>
      <ArrowSvg />
    </StyledButton>
  );

export default ArrowLink;
