import SectionTitle from '@components/ui/SectionTitle';
import styled from 'styled-components';

export const StyledTitle = styled(SectionTitle)`
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0.02em;
  line-height: 52px;
  text-transform: none;
  min-width: 500px;
  margin: 0;
  color: ${({ theme, color }) =>
    color === 'white'
      ? theme.colors.primary.white
      : theme.colors.primary.light_500};

  @media screen and (max-width: 450px) {
    font-size: 24px;
  }

  @media screen and (max-width: 320px) {
    min-width: 250px;
  }
`;

export const ServicesDescription = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 113px;
  padding: 48px 0;

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 590px;

  @media screen and (max-width: 470px) {
    max-width: 300px;
  }
`;

export const StyledText = styled(SectionTitle)`
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.02em;
  line-height: 32px;
  text-transform: none;
  margin: 0;
  color: ${({ theme, color }) =>
    color === 'white'
      ? theme.colors.primary.white
      : theme.colors.primary.light_500};

  @media screen and (max-width: 420px) {
    font-size: 18px;
  }
`;
