import Container from '@components/ui/Container';
import Section from '@components/ui/Section';
import SectionTitle from '@components/ui/SectionTitle';
import Arrow from '@ui/Arrow';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';

import line from './assets/line.svg';

const StyledSection = styled(Section)`
  padding: 0;
`;

const EmptyContainer = styled.div`
  height: 84vh;

  @media screen and (max-width: 1090px) {
    height: 90vh;
  }
`;

const StyledTween = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url(${line.src}) no-repeat;
  background-position: bottom right;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.primary.dark};
  padding: 180px 0;

  @media screen and (max-width: 790px) {
    padding: 120px 0;
  }

  @media screen and (max-width: 520px) {
    padding: 90px 0;
  }
`;

const InfoWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const InfoTitle = styled(SectionTitle)`
  font-weight: 500;
  font-size: 40px;
  letter-spacing: 0.02em;
  line-height: 123%;
  text-transform: lowercase;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.secondary.silver_1000};

  @media screen and (max-width: 280px) {
    font-size: 34px;
  }
`;

const OrangeText = styled.span`
  padding: 0 16px;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.primary.orange};
`;

const StyledLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  max-width: 325px;
  color: ${({ theme }) => theme.colors.secondary.silver_30};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.secondary.silver_60};

    svg path {
      fill: ${({ theme }) => theme.colors.secondary.silver_60};
    }
  }
`;

const LinkText = styled(SectionTitle)`
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 123%;
  text-transform: none;
  margin: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 128px;
  align-items: center;
  margin-top: 96px;

  @media screen and (max-width: 1240px) {
    column-gap: 72px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  min-width: 482px;
  column-gap: 12px;

  @media screen and (max-width: 1240px) {
    min-width: 280px;
  }

  @media screen and (max-width: 280px) {
    min-width: 240px;
  }
`;

const ContentText = styled(SectionTitle)`
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.02em;
  line-height: 130%;
  text-transform: lowercase;
  max-width: 185px;
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary.silver_40};
`;

const ContentNumb = styled(SectionTitle)`
  font-weight: 400;
  font-size: 128px;
  line-height: 125%;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary.white};
`;

const InfoSection = () => {
  const { t } = useTranslation('info');

  const content = [
    {
      id: 0,
      number: t('numb_1'),
      text: t('text_1'),
    },
    {
      id: 1,
      number: t('numb_2'),
      text: t('text_2'),
    },
  ];

  return (
    <StyledSection className="blackSection" id="info">
      <EmptyContainer />
      <Controller>
        <Scene triggerHook={0.9} duration={200}>
          <Tween from={{ xPercent: -25 }} to={{ xPercent: 0 }}>
            <StyledTween id="move">
              <InfoWrapper>
                <InfoTitle>
                  <Trans
                    i18nKey="info:title"
                    components={{ c: <OrangeText /> }}
                  />
                </InfoTitle>
                <Link href="/about-us" passHref>
                  <StyledLink>
                    <LinkText>{t('cta')}</LinkText>
                    <Arrow />
                  </StyledLink>
                </Link>
                <ContentWrapper>
                  {content.map((item) => (
                    <Content key={item.id}>
                      <ContentNumb>{item.number}</ContentNumb>
                      <ContentText>{item.text}</ContentText>
                    </Content>
                  ))}
                </ContentWrapper>
              </InfoWrapper>
            </StyledTween>
          </Tween>
        </Scene>
      </Controller>
    </StyledSection>
  );
};

export default InfoSection;
