import StackList from '@components/ui/StackList';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ size }) => {
    if (size === 'small') {
      return '93px';
    }

    if (size === 'medium') {
      return '234px';
    }

    if (size === 'big') {
      return '316px';
    }

    return 0;
  }};
  gap: 24px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.colors.secondary.silver_1000};
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  margin: 0;
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.02em;
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary.silver_1000};
`;

const MobileInfoContent = ({ data, size }) => {
  const { t } = useTranslation('cases');

  return (
    <>
      <StackList
        data={data.stack}
        color="dark"
        borderSize={1}
        columnGap={16}
        fontSize={20}
        width={360}
        position="right"
      />
      <StyledContainer size={size}>
        {data.info.map((info) => (
          <TextContainer key={info.title}>
            <Title>{t(info.title)}</Title>
            <DescriptionContainer>
              {info.text.map((text) => (
                <Description key={text}>{t(text)}</Description>
              ))}
            </DescriptionContainer>
          </TextContainer>
        ))}
      </StyledContainer>
    </>
  );
};

export default MobileInfoContent;
