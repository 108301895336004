import AnimatedContainer from '@components/ui/AnimatedContainer';
import Container from '@components/ui/Container';
import Section from '@components/ui/Section';
import SectionTitle from '@components/ui/SectionTitle';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

import {
  ServicesDescription,
  StyledDescription,
  StyledText,
  StyledTitle,
} from '../StyledComponents';

const StyledSection = styled(Section)`
  background: ${({ theme }) => theme.colors.secondary.silver_1000};
  position: relative;
  padding: 128px 0;
`;

const Title = styled(SectionTitle)`
  font-weight: 500;
  font-size: 68px;
  letter-spacing: 0.02em;
  line-height: 80px;
  text-transform: none;
  margin: 0 0 48px;
  color: ${({ theme }) => theme.colors.primary.light_500};

  @media screen and (max-width: 270px) {
    font-size: 56px;
  }
`;

const ServicesSection = () => {
  const { t } = useTranslation('services');

  const values = [
    {
      class: 1,
      title: t('subtitle_1'),
      component: <StyledText>{t('text_1')}</StyledText>,
    },
    {
      class: 2,
      title: t('subtitle_2'),
      component: <StyledText>{t('text_2')}</StyledText>,
    },
    {
      class: 3,
      title: t('subtitle_3'),
      component: <StyledText>{t('text_3')}</StyledText>,
    },
    {
      class: 4,
      title: t('subtitle_4'),
      component: <StyledText>{t('text_4')}</StyledText>,
    },
  ];

  return (
    <StyledSection id="services">
      <Container>
        <AnimatedContainer className="value_services-title">
          <Title>{t('title')}</Title>
        </AnimatedContainer>
        {values.map((value, index) => (
          <AnimatedContainer
            className={`value-services-${value.class}`}
            key={`value-services-${index + 1}`}
          >
            <ServicesDescription>
              <StyledTitle>{value.title}</StyledTitle>
              <StyledDescription>{value.component}</StyledDescription>
            </ServicesDescription>
          </AnimatedContainer>
        ))}
      </Container>
    </StyledSection>
  );
};

export default ServicesSection;
