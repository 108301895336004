import icons from '@icons/index';
import Image from 'next/image';
import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    bottom: -100px;
    display: none;
    opacity: 0;
  }

  100% {
    bottom: 0;
    display: grid;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    bottom: 0;
    display: grid;
    opacity: 1;
  }

  100% {
    bottom: -100px;
    display: none;
    opacity: 0;
  }
`;

const fadeInMobile = keyframes`
  0% {
    bottom: 50px;
    display: none;
    opacity: 1;
  }

  100% {
    bottom: 0;
    display: grid;
    opacity:1;
  }
`;

const fadeOutMobile = keyframes`
  0% {
    bottom: 0;
    display: grid;
    opacity: 1;
  }

  100% {
    bottom: 50px;
    display: none;
    opacity:0;
  }
`;

const StackTilesWrapper = styled.div`
  width: 396px;
  position: relative;
  opacity: ${({ active, hideCurrent, isCurrentId }) =>
    active && !hideCurrent && !isCurrentId ? 0 : 1};
  display: ${({ active }) => (active ? 'grid' : 'none')};
  grid-row-gap: 40px;
  grid-column-gap: 48px;
  grid-template: auto / repeat(3, 1fr);
  align-self: flex-end;
  transform: scaleY(-1);
  justify-items: center;
  ${({ hideCurrent, isCurrentId }) =>
    hideCurrent &&
    !isCurrentId &&
    css`
      animation: ${fadeOut} 0.2s 0.2s linear forwards;
    `}
  ${({ active, hideCurrent, isCurrentId }) =>
    active &&
    !hideCurrent &&
    !isCurrentId &&
    css`
      animation: ${fadeIn} 0.2s 0.2s linear forwards;
    `};

  @media screen and (max-width: 1024px) {
    align-self: flex-start;
    transform: scaleY(1);
    grid-column-gap: 40px;
    width: 388px;
    ${({ hideCurrent, isCurrentId }) =>
      hideCurrent &&
      !isCurrentId &&
      css`
        animation: ${fadeOutMobile} 0.2s 0.2s linear forwards;
      `}
    ${({ active, hideCurrent, isCurrentId }) =>
      active &&
      !hideCurrent &&
      !isCurrentId &&
      css`
        animation: ${fadeInMobile} 0.2s 0.2s linear forwards;
      `};
  }

  @media screen and (max-width: 520px) {
    width: 296px;
    grid-gap: 25px;
  }

  @media screen and (max-width: 320px) {
    grid-template: auto / repeat(2, 1fr);
    width: 245px;
  }
`;

const StackTile = styled.div`
  width: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  transform: scaleY(-1);
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 144%;
  color: ${({ theme }) => theme.colors.secondary.silver_100};

  @media screen and (max-width: 1024px) {
    transform: scaleY(1);
  }

  @media screen and (max-width: 450px) {
    width: 90px;
  }
`;

const StackIcon = styled.div`
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;

  @media screen and (max-width: 520px) {
    width: 55px;
    height: 55px;
    padding: 10px;
  }
`;

const StackTiles = ({ active, techs, hideCurrent, isCurrentId }) => (
  <StackTilesWrapper
    active={active}
    hideCurrent={hideCurrent}
    isCurrentId={isCurrentId}
  >
    {techs.map((tech) => (
      <StackTile key={tech}>
        <StackIcon>
          <Image
            width={48}
            height={48}
            src={icons[tech.replace(/\s|\./, '')]}
            quality={100}
          />
        </StackIcon>
        {tech}
      </StackTile>
    ))}
  </StackTilesWrapper>
);

export default StackTiles;
