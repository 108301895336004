import AnimatedContainer from '@components/ui/AnimatedContainer';
import Container from '@components/ui/Container';
import Section from '@components/ui/Section';
import SectionTitle from '@components/ui/SectionTitle';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import styled from 'styled-components';

import stack from './data';
import StackTiles from './modules/StackTiles';

const StyledSection = styled(Section)`
  position: relative;
  background: ${({ theme }) => theme.colors.secondary.silver_1000};
  padding: 172px 0 180px;

  @media screen and (max-width: 414px) {
    padding: 0 0 90px;
  }

  @media screen and (max-width: 375px) {
    padding: 90px 0;
  }
`;

const StackTitle = styled(SectionTitle)`
  font-weight: 500;
  font-size: 68px;
  line-height: 80px;
  letter-spacing: 0.02em;
  margin: 0 0 128px;
  color: ${({ theme }) => theme.colors.primary.light_500};
  text-transform: none;

  @media screen and (max-width: 790px) {
    margin-bottom: 20px;
  }
`;

const StackWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StackContent = styled.div`
  min-height: 466px;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  gap: 84px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 48px;
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  font-size: 68px;
  line-height: 80px;
  letter-spacing: 0.02em;
  writing-mode: tb-rl;
  transform: rotate(180deg);
  cursor: pointer;

  #span_numb {
    color: ${({ selected, theme }) =>
      selected
        ? theme.colors.primary.orange
        : theme.colors.secondary.silver_40};
    font-weight: ${({ selected }) => (selected ? '700' : '400')};
  }

  :hover {
    #span_numb {
      color: ${({ selected, theme }) =>
        selected
          ? theme.colors.primary.orange
          : theme.colors.secondary.silver_900};
    }

    #span_text {
      color: ${({ selected, theme }) =>
        selected
          ? theme.colors.primary.light_500
          : theme.colors.secondary.silver_70};
    }
  }

  @media screen and (max-width: 1024px) {
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }

  @media screen and (max-width: 320px) {
    line-height: 50px;
  }
`;

const StackSpanText = styled.span`
  color: ${({ theme }) => theme.colors.primary.light_500};
  font-weight: ${({ selected }) => (selected ? '500' : '400')};

  @media screen and (max-width: 900px) {
    font-size: 52px;
  }

  @media screen and (max-width: 520px) {
    font-size: 42px;
  }
`;

const StackSpanNumb = styled.span`
  font-family: 'PTMono';

  @media screen and (max-width: 900px) {
    font-size: 52px;
  }

  @media screen and (max-width: 520px) {
    font-size: 42px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 42px;
  transition: width 0.2s linear;
  width: ${({ active }) =>
    active ? 'calc(100% - 660px)' : 'calc(100% - 1108px)'};

  @media screen and (max-width: 1380px) {
    width: ${({ active }) =>
      active ? 'calc(100% - 660px)' : 'calc(100% - 1052px)'};
  }

  @media screen and (max-width: 1320px) {
    width: ${({ active }) =>
      active ? 'calc(100% - 535px)' : 'calc(100% - 975px)'};
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: auto;
  }
`;

const StackSection = () => {
  const { t } = useTranslation('cases');
  const [currentStack, setCurrentStack] = useState(stack[0]);
  const [hideCurrent, setHideCurrent] = useState(null);
  const [isCurrentId, setIsCurrentId] = useState(false);

  return (
    <StyledSection id="stack">
      <Container>
        <AnimatedContainer className="value-stack">
          <StackTitle>{t('stack')}</StackTitle>
        </AnimatedContainer>
        <StackWrapper>
          <StackContent>
            {stack.map((_stack) => (
              <StyledContainer active={_stack === currentStack} key={_stack.id}>
                <Stack
                  selected={_stack === currentStack}
                  onClick={() => {
                    setHideCurrent(currentStack);
                    setIsCurrentId(currentStack?.id === _stack.id);
                    setTimeout(() => {
                      setCurrentStack(_stack);
                      setHideCurrent(null);
                    }, 600);
                  }}
                >
                  <StackSpanNumb id="span_numb">
                    {`0${_stack.id + 1}`}
                  </StackSpanNumb>
                  <StackSpanText
                    id="span_text"
                    selected={_stack === currentStack}
                  >
                    {_stack.title}
                  </StackSpanText>
                </Stack>
                <StackTiles
                  hideCurrent={_stack === hideCurrent}
                  isCurrentId={isCurrentId}
                  active={_stack === currentStack}
                  techs={currentStack.techs}
                />
              </StyledContainer>
            ))}
          </StackContent>
        </StackWrapper>
      </Container>
    </StyledSection>
  );
};

export default StackSection;
