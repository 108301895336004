import { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  opacity: ${({ active }) => (active ? 0 : 1)};
  transform: ${({ active }) => (active ? 'translate3d(0, 80px, 0)' : 'none')};
  transition: opacity 0.2s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &[class*='value-format'],
  &[class*='value-services'],
  &[class*='value-case'] {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.colors.secondary.silver_40}`};
  }

  &.value-format-3,
  &.value-services-4,
  &.value-case-4,
  &.value-case-9,
  &.value-case-10 {
    border: none;
  }
`;

const AnimatedContainer = ({ className, children }) => {
  const [isActive, setActive] = useState(false);

  const setHighlight = () => {
    if (typeof document !== 'undefined') {
      const currentValue = document.querySelector(`.${className}`);
      const blockPosition = currentValue?.getBoundingClientRect().top;
      const textHeight = currentValue?.getBoundingClientRect().height;
      const textEndPosition = blockPosition - textHeight / 2;
      const isStart =
        blockPosition - textHeight / 2 === window.pageYOffset ||
        blockPosition < window.innerHeight + textHeight / 2;
      const isEnd = window.innerHeight - textHeight > textEndPosition;

      if (isStart && !isEnd) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', setHighlight);
    }

    return () => window.removeEventListener('scroll', setHighlight);
  }, []);

  return (
    <StyledContainer className={className} active={isActive}>
      {children}
    </StyledContainer>
  );
};

export default AnimatedContainer;
